import React from "react"
import { Link } from 'gatsby-plugin-modal-routing-3'
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import Seo from "../components/seo"
import Mainnav from "../components/mainnav"
import Logo from "../components/logo"
import {Helmet} from "react-helmet"
import Current from "../components/current"
import About from "../components/about"
import Actions from "../components/actions"

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Marquee from "react-fast-marquee"
import CookieConsent from "react-cookie-consent"

const Mainwrap = styled.main`
    margin: 10vh auto;

    .layout {
        margin: 0 5vw;
    }
    button {
      background: transparent;
      border: 0;
      padding: 0;
      color: rgba(255,255,255,.66);
      position: absolute;
      z-index: 7;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      &:hover {
        cursor: pointer;
        color: rgba(255,255,255,1);
      }
    }
    .slide a img {
      transition: all 0.3s ease;
    }
    .slide a:hover img {
      filter: brightness(33%);
    }
    a video {
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.75;
      }
    }

`

export default function Home() {
  return (
    <>
    <Seo />
    <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;500;600;700&family=Texturina:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />
        <script
          src="https://code.jquery.com/jquery-3.4.1.min.js"
          integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
          crossOrigin="anonymous">
        </script>
    </Helmet>
    <Logo />
    <Mainnav />
    <Mainwrap>
    {/* <Marquee style={{position: 'absolute', width: '100%', transform: 'rotate(-5deg)', zIndex: '3', fontFamily: 'Texturina', fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: '100', letterSpacing: '15px'}} gradient={false} ><a href="https://prepostness.designinquiry.net">Prepostness June 20–26, 2021 Vinalhaven, Maine</a></Marquee> */}
    <Current />
    <Actions />
    <About />
    </Mainwrap>
    <CookieConsent
  location="bottom"
  buttonText="I Accept"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
  This website uses cookies to enhance the user experience.{" "}
 
    </CookieConsent>
    </>
  )
}
